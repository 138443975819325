import useTitle from "../../hooks/useTitle";
import StyleProvider from "../../shared/StyleProvider";

export default function Support() {
  useTitle("Support");

  return (
    <StyleProvider>
      <div className="pb-10 px-3 md:px-12 py-8 h-screen">
        <h1 className="text-2xl md:text-3xl lg:text-4xl py-4 text-center font-lemonMilk text-black">
          Support
        </h1>
        <p className="text-black pt-5 md:pt-8 text-balance text-base md:text-lg text-center font-monospaceTypewriter">
          Email:{" "}
          <a
            href="mailto:support@mirrorltd.com"
            target="_blank"
            rel="noreferrer"
            className="hover:text-gradientPrimary hover:underline duration-300"
          >
            support@mirrorltd.com
          </a>
        </p>
        <p className="text-black pt-2 md:pt-5 text-base md:text-lg text-center font-monospaceTypewriter">
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSeiV4rQloQUOdyn9SkD_6u3_6ns_KN0cT-yValg46FTXfEtHw/viewform"
            target="_blank"
            rel="noreferrer"
            className="hover:text-gradientPrimary hover:underline duration-300"
          >
            Report Copyright Infringement
          </a>
        </p>
      </div>
    </StyleProvider>
  );
}
